@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background-primary: #1c1c1e;
    --background-secondary: #2c2c2e;
    --text-primary: #f5f5f7;
    --text-secondary: #a1a1a6;
    --accent-primary: #0a84ff;
    --accent-secondary: #5e5ce6;
  }

  body {
    @apply bg-background text-text-primary antialiased min-h-screen;
    font-feature-settings: "ss01" on, "ss02" on, "cv01" on, "cv02" on;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-sans font-bold tracking-tight;
  }

  h1 { @apply text-4xl lg:text-5xl mb-6; }
  h2 { @apply text-3xl lg:text-4xl mb-5; }
  h3 { @apply text-2xl lg:text-3xl mb-4; }
  h4 { @apply text-xl lg:text-2xl mb-3; }
  h5 { @apply text-lg lg:text-xl mb-2; }
  h6 { @apply text-base lg:text-lg mb-2; }
}

@layer components {
  /* Layout Components */
  .container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  .section {
    @apply py-12 md:py-20;
  }

  /* Card Components */
  .card {
    @apply bg-background-secondary rounded-xl shadow-md p-6 border border-gray-800;
  }

  .card-header {
    @apply flex flex-col space-y-1.5 p-6;
  }

  .card-title {
    @apply text-2xl font-semibold text-text-primary;
  }

  .card-description {
    @apply text-sm text-text-secondary;
  }

  .card-content {
    @apply p-6 pt-0;
  }

  .card-footer {
    @apply flex items-center p-6 pt-0;
  }

  /* Button Components */
  .btn {
    @apply inline-flex items-center justify-center px-4 py-2 rounded-lg font-medium transition-all duration-200
    disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-primary/90 focus:ring-primary/50;
  }

  .btn-secondary {
    @apply bg-secondary text-white hover:bg-secondary/90 focus:ring-secondary/50;
  }

  .btn-outline {
    @apply border-2 border-primary text-primary hover:bg-primary hover:text-white focus:ring-primary/50;
  }

  .btn-danger {
    @apply bg-danger text-white hover:bg-danger/90 focus:ring-danger/50;
  }

  .btn-success {
    @apply bg-success text-white hover:bg-success/90 focus:ring-success/50;
  }

  /* Form Components */
  .form-group {
    @apply space-y-2 mb-4;
  }

  .form-label {
    @apply block text-sm font-medium text-text-secondary;
  }

  .form-input {
    @apply w-full rounded-lg border border-gray-700 bg-background-secondary px-4 py-2
    text-text-primary placeholder:text-text-secondary/70
    focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent
    disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .form-select {
    @apply w-full rounded-lg border border-gray-700 bg-background-secondary px-4 py-2
    text-text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent;
  }

  .form-textarea {
    @apply w-full rounded-lg border border-gray-700 bg-background-secondary px-4 py-2
    text-text-primary placeholder:text-text-secondary/70
    focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent
    disabled:opacity-50 disabled:cursor-not-allowed;
  }

  /* Navigation Components */
  .nav-link {
    @apply text-text-secondary hover:text-text-primary transition-colors duration-200;
  }

  .nav-link-active {
    @apply text-primary font-medium;
  }

  /* Table Components */
  .table-container {
    @apply w-full overflow-x-auto rounded-lg border border-gray-700;
  }

  .table {
    @apply min-w-full divide-y divide-gray-700;
  }

  .table-header {
    @apply bg-background-secondary;
  }

  .table-header-cell {
    @apply px-6 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider;
  }

  .table-body {
    @apply divide-y divide-gray-700 bg-background;
  }

  .table-row {
    @apply hover:bg-background-secondary/50 transition-colors duration-150;
  }

  .table-cell {
    @apply px-6 py-4 whitespace-nowrap text-sm text-text-primary;
  }

  /* Alert Components */
  .alert {
    @apply p-4 rounded-lg border mb-4;
  }

  .alert-info {
    @apply bg-info/10 border-info text-info;
  }

  .alert-success {
    @apply bg-success/10 border-success text-success;
  }

  .alert-warning {
    @apply bg-warning/10 border-warning text-warning;
  }

  .alert-danger {
    @apply bg-danger/10 border-danger text-danger;
  }

  /* Badge Components */
  .badge {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
  }

  .badge-primary {
    @apply bg-primary/10 text-primary;
  }

  .badge-secondary {
    @apply bg-secondary/10 text-secondary;
  }

  /* Modal Components */
  .modal-overlay {
    @apply fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm;
  }

  .modal {
    @apply fixed inset-0 flex items-center justify-center p-4;
  }

  .modal-content {
    @apply bg-background-secondary rounded-xl shadow-xl max-w-md w-full mx-auto;
  }

  /* Loading Indicators */
  .loading-spinner {
    @apply animate-spin rounded-full border-4 border-gray-700 border-t-primary h-8 w-8;
  }

  .loading-dots {
    @apply flex space-x-1;
  }

  .loading-dot {
    @apply h-2 w-2 bg-primary rounded-full animate-bounce;
  }
}

@layer utilities {
  /* Custom Scrollbar */
  .scrollbar-custom {
    scrollbar-width: thin;
    scrollbar-color: var(--accent-primary) var(--background-secondary);
  }

  .scrollbar-custom::-webkit-scrollbar {
    @apply w-2 h-2;
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    @apply bg-background-secondary rounded-full;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-full;
  }

  /* Glass Morphism */
  .glass {
    @apply backdrop-blur-md bg-white/10 border border-white/20;
  }

  /* Text Gradient */
  .text-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary;
  }

  /* Hover Effects */
  .hover-lift {
    @apply transition-transform duration-200 hover:-translate-y-1;
  }

  .hover-scale {
    @apply transition-transform duration-200 hover:scale-105;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

.animate-slideIn {
  animation: slideIn 0.3s ease-in-out;
}

/* Media Queries */
@media (max-width: 768px) {
  .container {
    @apply px-4;
  }
}

/* Dark Mode Overrides */
@media (prefers-color-scheme: dark) {
  body {
    @apply bg-background text-text-primary;
  }

  input, textarea, select {
    @apply bg-background-secondary text-text-primary border-gray-700;
  }
}